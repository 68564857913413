/**
 * Created by spepa on 16.02.2023
 */

var CardTableView = cleverapps.Layout.extend({
    ctor: function (tableColumns) {
        var styles = cleverapps.styles.CardTableView;

        this.columnViews = tableColumns.map(function (column) {
            return new CardColumnView(column);
        }, this);

        this._super(this.columnViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this.setupChildren();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.CardTableView;
        var isWide = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL;
        var scene = cleverapps.scenes.getRunningScene();
        var offset = isWide ? styles.wideOffset : styles.offset;

        cleverapps.UI.fitToBox(this, {
            width: scene.width - styles.paddingX
        });

        this.setAnchorPoint(0.5, 1);
        if (isWide) {
            this.setPositionRound({
                x: { align: "center", dx: offset.x },
                y: { align: "top", dy: offset.y }
            });
        } else {
            this.setPositionRound({
                x: { align: "center" },
                y: { align: "top", dy: -this.columnViews[0].height * this.scale + offset.y }
            });
        }
    },

    _repositionCards: function () {
        this.columnViews.forEach(function (column) {
            column.cardColumn.repositionCards();
        });
    }
});

cleverapps.overrideStyles(cleverapps.styles.CardTableView, {
    margin: 20,
    paddingX: 60,

    offset: {
        y: -137
    },

    wideOffset: {
        x: -40,
        y: -130
    }
});