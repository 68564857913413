/**
 * Created by spepa on 09.02.2023
 */

MovesLog.prototype.executeUndo = function () {
    var move = this.log.pop();

    switch (move.type) {
        case MovesLog.MOVE_PLAY_CARD:
            this.undoMove(move);
            break;
        case MovesLog.MOVE_SHIFT_CARD:
            this.undoShift(move);
            break;
    }

    Game.currentGame.move = Card.MOVES.SHIFT;
    Game.currentGame.score.setPoints(move.options.score);
    Game.currentGame.incMoves();
    this.trigger("change");
};

MovesLog.prototype.undoMove = function (move) {
    var from = Game.currentGame.columns[move.options.from];
    var to = Game.currentGame.columns[move.options.to];

    from.cards.forEach(function (card, ind) {
        if (ind < move.options.firstOpenIndex && card.isOpen()) {
            card.flip();
        }
    });

    var cards = to.cards.slice(to.cards.length - move.options.cardsCount);
    to.removeCards(cards);
    from.addCards(cards);
    var time = cards[0].calcMoveTime();
    from.trigger("glee", time);

    Game.currentGame.counter.setTimeout(function () {}, (time + 0.1) * 1000);
    Game.currentGame.playCardSfx(from);
};

MovesLog.prototype.undoShift = function () {
    var handCol = Game.currentGame.columnsByType(CardColumnTypes.HAND)[0];

    if (handCol.isEmpty()) {
        var cards = Game.currentGame.hand.stack.slice().reverse();
        Game.currentGame.hand.stack = [];
        for (var i = 0; i < cards.length; i++) {
            Game.currentGame.hand.trigger("shift");
            cards[i].flip();
        }
        handCol.addCards(cards);
        handCol.trigger("updateVisibility");
    } else {
        var topCard = handCol.getHead();
        handCol.removeCards(topCard);
        topCard.flip();
        Game.currentGame.hand.push(topCard);
    }

    Game.currentGame.counter.setTimeout(function () {}, (Card.PLAY_TIMEOUT + 0.1) * 1000);

    cleverapps.audio.playSound(bundles.game.urls.shift_card_sfx);
};