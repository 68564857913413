/**
 * Created by spepa on 12.02.2023
 */

var HandBlockView = cleverapps.Layout.extend({
    ctor: function (hand, handColumns) {
        var styles = cleverapps.styles.HandBlockView;

        this.handColumn = new CardColumnView(handColumns[0]);
        this.handColumn.setContentSize(styles.columnWidth, Card.HEIGHT);
        this.handView = new HandView(hand);

        this._super([this.handColumn, this.handView], {});
        this.setupChildren();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.HandBlockView;
        var isWide = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL;
        var scene = cleverapps.scenes.getRunningScene();
        var table = scene.cardTableView;
        var tableRight = table.x + table.width * table.anchorX * table.scale;

        this.options = {
            direction: isWide ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
            margin: isWide ? styles.wideMargin : styles.margin
        };
        this.children.forEach(function (child) {
            child.setPositionRound(0, 0);
        });
        this.reshape();
        this.setScale(table.scale);

        if (isWide) {
            this.setAnchorPoint(0.5, 1);
            this.setPositionRound({
                x: tableRight + (scene.width - tableRight) / 2,
                y: { align: "top", dy: styles.wideOffset.y }
            });
        } else {
            this.setAnchorPoint(1, 1);
            this.setPositionRound({
                x: tableRight,
                y: { align: "top", dy: styles.offset.y }
            });
        }
    }
});

cleverapps.styles.HandBlockView = {
    margin: 60,
    wideMargin: 20,
    columnWidth: 300,

    offset: {
        y: -110
    },

    wideOffset: {
        y: -260
    }
};
