/**
 * Created by spepa on 10.03.2023
 */

var CardsHintHalo = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.card.frames.card_halo);
        this.setOpacity(0);
    },

    play: function (manualRemove) {
        var scaleX = 1.05;
        var dWidth = this.width * (scaleX - 1);
        var scaleY = (this.height + dWidth) / this.height;

        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.4, scaleX, scaleY),
                new cc.FadeIn(0.4)
            ),
            new cc.CallFunc(function () {
                !manualRemove && this.remove();
            }.bind(this))
        ));
    },

    remove: function () {
        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.2, 1),
                new cc.FadeOut(0.4)
            ),
            new cc.RemoveSelf()
        ));
    }
});