/**
 * Created by spepa on 07.02.2023
 */

var OpenCardsView = cleverapps.Layout.extend({
    ctor: function (openColumns) {
        var styles = cleverapps.styles.OpenCardsView;

        this.columnViews = openColumns.map(function (column) {
            return new CardColumnView(column);
        });

        this._super(this.columnViews, {
            margin: styles.margin
        });
        this.setupChildren();
    },

    setupChildren: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var styles = cleverapps.styles.OpenCardsView;
        var isWide = cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL;
        var table = scene.cardTableView;
        this.options.direction = isWide ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL;
        this.children.forEach(function (child) {
            child.setPositionRound(0, 0);
        });
        this.reshape();
        this.setScale(table.scale);

        this.setAnchorPoint(0, 1);
        if (isWide) {
            this.setPositionRound({
                x: styles.wideOffset.x,
                y: { align: "top", dy: styles.wideOffset.y }
            });
        } else {
            var tableLeft = table.x - table.width * table.anchorX * table.scale;
            this.setPositionRound({
                x: tableLeft,
                y: { align: "top", dy: styles.offset.y }
            });
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.OpenCardsView, {
    margin: 20,

    offset: {
        y: -110
    },

    wideOffset: {
        x: 50,
        y: -130
    }
});
