/**
 * Created by spepa on 08.02.2023
 */

Card.prototype.isNext = function (otherCard) {
    return this.isLowerCard(otherCard);
};

Card.prototype.isLowerCard = function (otherCard, onlyRank) {
    if (this.isEqualSuit(otherCard) || onlyRank) {
        if (this.getRank() === "a") {
            return false;
        }
        if (this.getRank() === "2" && otherCard.getRank() === "a") {
            return true;
        }
        return this.getCardValue() - otherCard.getCardValue() === 1;
    }
    return false;
};

Card.prototype.isSameColor = function (otherCard) {
    return this.getSuit() === otherCard.getSuit() || ["sc", "cs", "dh", "hd"].includes(this.getSuit() + otherCard.getSuit());
};

Card.prototype.canStartDrag = function () {
    if (Game.currentGame.counter.isActive() || Game.currentGame.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return false;
    }
    if (cleverapps.forces.isRunningForce() || this.inAnimation()) {
        return false;
    }
    return this.owner && this.owner.isMovable && this.owner.isMovable(this);
};

Card.prototype.onDragBegin = function () {
    this.getTail().forEach(function (card) {
        card.trigger("beginDrag");
    });
};

Card.prototype.onMoveDrag = function (touch) {
    this.getTail().forEach(function (card) {
        card.trigger("dragMove", touch);
    });

    Game.currentGame.onMoveDrag(this.getTail());
};

Card.prototype.onDragFinish = function () {
    if (!Game.currentGame.tryMove(this.getTail(), true)) {
        this.cancelDrag();
    } else {
        this.getTail().forEach(function (card) {
            card.trigger("endDrag");
        });
    }
};

Card.prototype.cancelDrag = function () {
    Game.currentGame.counter.setTimeout(function () {}, Card.DRAG_CANCEL_TIMEOUT * 1000);
    this.owner.trigger("updateCards");
};

Card.prototype.getTail = function () {
    if (this.owner.type === CardColumnTypes.TABLE) {
        return this.owner.getTail(this);
    }

    return [this];
};

Card.prototype.calcMoveTime = function () {
    var speed = 4000;
    var distance = cc.pDistance(this.onGetView().getPosition(), CardView.convertToViewPosition(this));
    return Math.max(distance / speed, 0.1);
};

Card.AUTO_TIMEOUT = 0.1;
Card.PLAY_TIMEOUT = 0.2;
Card.HAND_RETURN_TIMEOUT = 0.2;
Card.DRAG_CANCEL_TIMEOUT = 0.25;
Card.SCALE_TIMEOUT = 0.1;

Card.SHOWUP_DURATION = 0.3;

Card.WIDTH = 195;
Card.HEIGHT = 270;

Card.GRID_XY = 2;