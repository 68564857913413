/**
 * Created by spepa on 08.02.2023
 */

CardView.prototype.ctor = cleverapps.extendFunc(CardView.prototype.ctor, function () {
    this._super.apply(this, arguments);
    this.card.on("beginDrag", this.prepareToDrag.bind(this), this);
    this.card.on("endDrag", this.endDrag.bind(this), this);
    this.card.on("dragMove", this.dragMove.bind(this), this);
    this.card.on("showHint", this.showHint.bind(this), this);
    this.card.on("highlight", this.highlight.bind(this), this);
    this.card.on("unhighlight", this.unhighlight.bind(this), this);
    this.card.on("playMagic", this.playMagic.bind(this), this);
});

CardView.prototype.addControls = function () {
    if (cleverapps.environment.isAdministratorScene() || cleverapps.environment.isEditorScene()) {
        return;
    }

    cleverapps.UI.onDrag(this, {
        onClick: this.card.onClickListener.bind(this.card),

        followPointer: function (options) {
            this.card.onMoveDrag(options);
        }.bind(this),

        onDragStart: function () {
            if (this.card.canStartDrag()) {
                this.card.onDragBegin();
                return true;
            }
        }.bind(this),

        onDragEnd: function () {
            this.card.onDragFinish();
        }.bind(this)
    });
};

CardView.prototype.prepareToDrag = function () {
    this.setParentMovingNode();
};

CardView.prototype.endDrag = function () {
    this.setScale(1);
};

CardView.prototype.dragMove = function (touch) {
    var movingNode = cleverapps.scenes.getMovingNode();

    var inOwnerPos = this.card.owner.getView().convertToWorldSpace(CardView.convertToViewPosition(this.card));
    var startPos = movingNode.convertToNodeSpace(inOwnerPos);
    var displacement = movingNode.convertTouchToNodeSpaceDisplacement(touch);
    this.setPositionRound(startPos.x + displacement.x, startPos.y + displacement.y);
};

CardView.prototype.setParentMovingNode = function () {
    var movingNode = cleverapps.scenes.getMovingNode();
    this.replaceParentSamePlace(movingNode);
    this.setScale(cleverapps.scenes.getRunningScene().cardTableView.scale);
};

CardView.prototype.wrongMove = function () {
    var styles = cleverapps.styles.CardView.wrongMove;
    var pos = this.getPosition();
    cleverapps.audio.playSound(bundles.game.urls.wrong_effect);

    this.card.registerAnimationTime(0.2);
    this.runAction(new cc.Sequence(
        new cc.MoveTo(0.05, pos.x + styles.dx, pos.y),
        new cc.MoveTo(0.05, pos.x - styles.dx, pos.y),
        new cc.MoveTo(0.05, pos.x, pos.y)
    ));
};

CardView.prototype.createHalo = function () {
    var tail = this.card.getTail();
    var dy = 0;
    if (tail.length > 1) {
        dy = (tail[0].y - tail[tail.length - 1].y) * resolutionScale;
    }

    var halo = new CardsHintHalo();
    halo.setContentSize(halo.width, halo.height + dy);
    halo.setPositionRound(this.width / 2, (this.height - dy) / 2);
    this.addChild(halo, -1);
    return halo;
};

CardView.prototype.showHint = function () {
    var halo = this.createHalo();
    halo.play();
};

CardView.prototype.highlight = function () {
    if (!this.halo) {
        this.halo = this.createHalo();
        this.halo.play(true);
    }
};

CardView.prototype.unhighlight = function () {
    if (this.halo) {
        this.halo.remove();
        delete this.halo;
    }
};

CardView.prototype.playMagic = function () {
    this.setParentMovingNode();
    var scale = this.getScale();

    this.runAction(new cc.Sequence(
        new cc.Spawn(
            new cc.MoveTo(0.6, this.x, this.y + cleverapps.styles.CardView.magic.dy),
            new cc.ScaleTo(0.6, scale * 1.1)
        ),
        new cc.DelayTime(0.35),
        new cc.ScaleTo(0.15, scale)
    ));
};

CardView.prototype.screenShowUp = function (silent) {
    if (silent) {
        this.setPositionRound(CardView.convertToViewPosition(this.card));
        return;
    }

    var handView = cleverapps.scenes.getRunningScene().handBlockView.handView;
    var handPos = this.parent.convertToNodeSpace(handView.parent.convertToWorldSpace(handView.getPosition()));
    this.setPosition(handPos);

    var parent = this.parent;
    var movingNode = cleverapps.scenes.getMovingNode();
    var targetPos = movingNode.convertToNodeSpace(parent.convertToWorldSpace(CardView.convertToViewPosition(this.card)));

    this.runAction(
        new cc.Sequence(
            new cc.ReplaceParent(movingNode),
            new cc.MoveTo(Card.SHOWUP_DURATION, targetPos),
            new cc.ReplaceParent(parent)
        )
    );
};

cleverapps.overrideStyles(cleverapps.styles.CardView, {
    wrongMove: {
        dx: -7
    },

    magic: {
        dy: 150
    }
});

cleverapps.styles.CardView.width = Card.WIDTH;
cleverapps.styles.CardView.height = Card.HEIGHT;
