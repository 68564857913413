/**
 * Created by spepa on 07.02.2023
 */

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EXTRA_HAND_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.EXTRA_HAND_TEXT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.UndoButton, {
    width: 200,
    height: 150
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    noBackground: true
});