/**
 * Created by spepa on 09.03.2023
 */

var HintBlock = cc.Node.extend({
    ctor: function (game) {
        this._super();
        this.game = game;

        var styles = cleverapps.styles.HintBlockView;
        this.setContentSize(styles);
        this.setAnchorPoint(0.5, 0.5);

        this.booster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_HINT);
        this.booster.on("noHintFound", this.showNoHint.bind(this), this);

        this.hintButton = new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            content: new cc.Sprite(bundles.game.frames.hint_icon_png),
            onClicked: this.onClicked.bind(this)
        });

        this.hintButton.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.hintButton);
    },

    onClicked: function () {
        cleverapps.meta.display({
            focus: "ExecuteHintBooster",
            action: function (f) {
                this.booster.onPressed(f);
            }.bind(this)
        });
    },

    showNoHint: function () {
        cleverapps.meta.distract({
            focus: "retryWindow",
            action: function (f) {
                new ProlongationWindow({
                    onGiveUp: function () {
                        this.game.lose();
                    }.bind(this)
                });
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        });
    }
});

cleverapps.styles.HintBlockView = {
    width: 200,
    height: 150
};