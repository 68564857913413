/**
 * Created by Andrey Popov on 28.02.2023
 */

var CardColumnView = cc.Node.extend({
    ctor: function (cardColumn) {
        this._super();
        this.cardColumn = cardColumn;
        this.setAnchorPoint(0.5, 0.5);

        this.setContentSize2(cleverapps.styles.CardColumnView);

        this.cardColumn.on("addCards", this.addCards.bind(this), this);
        this.cardColumn.on("updateCards", this.updateCards.bind(this), this);
        this.cardColumn.on("updateVisibility", this.updateVisibility.bind(this), this);
        this.cardColumn.on("highlight", this.highlight.bind(this), this);
        this.cardColumn.on("unhighlight", this.unhighlight.bind(this), this);
        this.cardColumn.on("quickAccept", this.quickAccept.bind(this), this);
        this.cardColumn.on("glee", this.playGlee.bind(this), this);
        this.cardColumn.on("onHide", this.playHide.bind(this), this);

        this.cardColumn.isHovered = this.isHovered.bind(this);
        this.cardColumn.getView = function () {
            return this;
        }.bind(this);

        if (this.cardColumn.type.emptyBg) {
            var bg = this.bg = new cleverapps.Spine(bundles.game.jsons[this.cardColumn.type.emptyBg]);
            this.bg.setAnimation(0, "idle", true);
            bg.setPositionRound(this.width / 2, this.height / 2);
            this.addChild(bg);
        }
    },

    quickAccept: function (duration) {
        var card = this.cardColumn.cards.at(-1);
        var cardView = card.onGetView();
        cardView.replaceParentSamePlace(this, { keepScale: true });
        this.playGlee();

        cardView.runAction(new cc.Sequence(
            new cc.Show(),
            cleverapps.UI.animateCard({
                cardView: cardView,
                position: { parent: this, point: CardView.convertToViewPosition(card) },
                duration: duration !== undefined ? duration : Card.AUTO_TIMEOUT
            }),
            new cc.CallFunc(this.updateVisibility.bind(this))
        ));
    },

    addCards: function (f, silent) {
        this.cardColumn.cards.forEach(function (card, index) {
            var cardView = CardView.createCardView(card);
            this.addChild(cardView);

            cardView.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.DelayTime(silent ? 0 : index * 0.2),
                new cc.CallFunc(function () {
                    cardView.setVisible(this.cardColumn.isCardVisible(index));
                    card.trigger("animateShowUp", silent);
                }.bind(this))
            ));
        }, this);

        Game.currentGame.setTimeout(f, silent ? 0 : ((this.cardColumn.cards.length - 1) * 0.2 + Card.SHOWUP_DURATION));
    },

    updateVisibility: function () {
        this.cardColumn.cards.forEach(function (card, index) {
            card.onGetView().setVisible(this.cardColumn.isCardVisible(index));
        }, this);
    },

    updateCards: function (silent) {
        this.unhighlight();
        var time = 0;

        this.cardColumn.cards.forEach(function (card, index) {
            var cardView = card.onGetView();
            if (!cardView) {
                return;
            }
            cardView.stopAllActions();
            cardView.replaceParentSamePlace(this, { keepScale: true });

            var visible = this.cardColumn.isCardVisible(index);

            if (visible && !silent) {
                time = Math.max(time, card.calcMoveTime());
                card.registerAnimationTime(time + 0.1);

                cardView.runAction(new cc.Sequence(
                    cleverapps.UI.animateCard({
                        cardView: cardView,
                        position: { parent: this, point: CardView.convertToViewPosition(card) },
                        duration: time,
                        easing: cc.easeIn(3)
                    }),
                    new cc.CallFunc(this.updateVisibility.bind(this))
                ));
            } else {
                cardView.setVisible(visible);
                cardView.setPositionRound(CardView.convertToViewPosition(card));
                if (silent) {
                    cardView.setScale(cardView.card.getScale());
                }
            }
        }, this);
    },

    playGlee: function (delay) {
        if (this.cardColumn.type.glee) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(delay || 0),
                new cc.CallFunc(function () {
                    var animation = new cleverapps.Spine(bundles.game.jsons.column_glee);
                    animation.setAnimation(0, "animation", false);
                    animation.setCompleteListenerRemove();
                    animation.setPositionRound(this.width / 2, this.height / 2);
                    this.addChild(animation, 1);
                }.bind(this))
            ));
        }
    },

    isHovered: function (card) {
        var cardRect = card.onGetView().getBoundingBox();
        var cardPos = this.convertToNodeSpace(cardRect);
        cardRect.x = cardPos.x;
        cardRect.y = -cardPos.y;
        cardRect = cc.rectSubPadding(cardRect, cc.padding(cleverapps.styles.CardColumnView.hoverPadding));

        return cc.rectIntersectsRect(this.calcRect(), cardRect);
    },

    highlight: function (cards) {
        if (this.ghost) {
            return;
        }

        var pos = cc.p(Card.WIDTH / 2, Card.HEIGHT / 2);
        if (!this.cardColumn.isEmpty()) {
            pos = cc.pSub(cc.p(this.cardColumn.getHead()), this.cardColumn.getCardOffset(cards[0]));
        }

        this.ghost = new cleverapps.Spine(bundles.game.jsons.columnhint);
        this.addChild(this.ghost);
        this.ghost.setPositionRound(pos);
        this.ghost.setAnimationAndIdleAfter("open", "idle");
    },

    unhighlight: function () {
        if (this.ghost && this.ghost.getCurrentAnimationName(0) !== "close") {
            this.ghost.setAnimation(0, "close", false);
            this.ghost.setCompleteListenerRemove(function () {
                delete this.ghost;
            }.bind(this));
        }
    },

    playHide: function () {
        this.bg && this.bg.setAnimation(0, "close", false);
    },

    calcRect: function () {
        var rect = this.cardColumn.calcRect();
        return cc.rect(
            rect.x * resolutionScale,
            rect.y * resolutionScale,
            rect.width * resolutionScale,
            rect.height * resolutionScale
        );
    },

    setupChildren: function () {
        this.updateCards(true);
    }
});

cleverapps.styles.CardColumnView = {
    width: 186,
    height: 267,

    hoverPadding: {
        x: 50,
        y: 50
    }
};