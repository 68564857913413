/**
 * Created by Andrey Popov on 22.03.2023
 */

var HideAnimations = function (f) {
    var cards;

    if (this.outcome === GameBase.OUTCOME_VICTORY) {
        this.game.score.hide();
        this.game.hand.trigger("onHide");
        this.game.columns.forEach(function (column) {
            column.trigger("onHide");
        });

        var createRing = function (cards, radius, delay) {
            cards.forEach(function (card, ind) {
                var angle = (ind / (cards.length / 2)) * Math.PI;
                var x = (radius * Math.cos(angle)) + cleverapps.resolution.getSceneSize().width / 2;
                var y = (radius * Math.sin(angle)) + cleverapps.resolution.getSceneSize().height / 2;

                card.onGetView().runAction(new cc.Sequence(
                    new cc.DelayTime(delay * ind),
                    new cc.Show(),
                    new cc.DelayTime(0.1),
                    new cc.ReplaceParent(cleverapps.scenes.getMovingNode()),
                    new cc.Spawn(
                        new cc.ScaleTo(0.3, 1),
                        new cc.MoveTo(0.3, x, y),
                        new cc.RotateTo(0.3, 90 - ind * (360 / cards.length))
                    )
                ));
            });
        };

        cards = Game.currentGame.listCards().sort(function (a, b) {
            a = a.getRank() === "a" ? -1 : a.getCardValue();
            b = b.getRank() === "a" ? -1 : b.getCardValue();
            return b - a;
        });

        var rings = [
            { radius: 500 * resolutionScale, cards: cards.splice(0, 25) },
            { radius: 300 * resolutionScale, cards: cards.splice(0, 17) },
            { radius: 120 * resolutionScale, cards: cards }
        ];

        var actions = rings.map(function (ring) {
            return function (f) {
                createRing(ring.cards, ring.radius, 0.025);
                cleverapps.timeouts.setTimeout(f, 25 * ring.cards.length);
            };
        });
        actions = actions.concat(
            function (f) {
                cleverapps.timeouts.setTimeout(f, 400);
            },
            rings.map(function (ring) {
                return function (f) {
                    createRing(ring.cards, ring.radius + cleverapps.resolution.getSceneSize().width, 0);
                    f();
                };
            }),
            function (f) {
                cleverapps.timeouts.setTimeout(f, 500);
            }
        );

        cleverapps.audio.playSound(bundles.game.urls.win_formation_sfx);
        new ActionPlayer(actions).play(f);
    } else {
        cards = cleverapps.Random.shuffle(this.game.listCards());
        this.game.score.hide();

        new ActionPlayer([
            function (f) {
                cleverapps.audio.playSound(bundles.game.urls.lose_animation_effect);
                cards.forEach(function (card, index) {
                    card.onGetView().setParentMovingNode();
                    card.onGetView().animateLose(cards.length - index);
                });
                cleverapps.timeouts.setTimeout(f, 1200);
            },

            function (f) {
                this.game.hand.trigger("onHide");
                this.game.columns.forEach(function (column) {
                    column.trigger("onHide");
                });

                cleverapps.timeouts.setTimeout(f, 400);
            }.bind(this)
        ]).play(f);
    }
};