/**
 * Created by Andrey Popov on 06.03.2023
 */

var CardColumnTypes = {
    OPEN: {
        glee: true,
        cardsVisible: 2,
        emptyBg: "card_open_base",
        moveCost: function (newOwner) {
            return newOwner.type === CardColumnTypes.TABLE ? -15 : 0;
        },
        canMove: function (card) {
            return this.getHead() === card;
        },
        canAccept: function (cards, fromDrag) {
            if (cards.length > 1) {
                return false;
            }
            if (this.isEmpty()) {
                return cards[0].getRank() === "a" && (fromDrag || cards[0].owner.type !== CardColumnTypes.OPEN);
            }
            return cards[0].isLowerCard(this.getHead());
        }
    },

    TABLE: {
        emptyBg: "card_column_base",
        moveCost: function (newOwner, cards) {
            var newHead = cleverapps.substract(this.cards, cards, function (card) {
                return card.value;
            }).at(-1);
            var value = newHead && !newHead.isOpen() ? 5 : 0;
            value += newOwner.type === CardColumnTypes.OPEN ? 10 : 0;
            return value;
        },
        canAccept: function (cards) {
            if (cleverapps.gameModes.tableAcceptAll) {
                return cards[0].owner !== this;
            }

            return this.isEmpty() ? cards[0].getRank() === "k"
                : this.getHead().isLowerCard(cards[0], true) && !this.getHead().isSameColor(cards[0]);
        },
        getOffset: function (card) {
            if (card.isOpen()) {
                var height = KlondikeScene.GetAvailableHeight();
                var closed = this.cards.filter(function (card) {
                    return !card.isOpen();
                }).length;
                height = closed > 0 ? (height - Card.HEIGHT - Card.GRID_XY * 12 * closed) : height - Card.HEIGHT;
                var coef = Math.floor(height / (this.cards.length - closed) / Card.GRID_XY);
                return { x: 0, y: Card.GRID_XY * Math.min(40, Math.max(12, coef)) };
            } 
            return { x: 0, y: Card.GRID_XY * 12 };
        },
        canMove: function (card) {
            return card.isOpen();
        }
    },

    HAND: {
        cardsVisible: 4,
        moveCost: function (newOwner) {
            return newOwner.type === CardColumnTypes.TABLE ? 5 : 10;
        },
        getOffset: function (card) {
            var isTop3 = this.cards.indexOf(card) >= this.cards.length - 3;
            return {
                x: isTop3 ? -Card.GRID_XY * 34 : 0,
                y: 0
            };
        },
        canMove: function (card) {
            return this.getHead() === card;
        }
    }
};