/**
 * Created by spepa on 09.03.2023
 */

HandView.prototype.ctor = cleverapps.extendFunc(HandView.prototype.ctor, function () {
    this._super.apply(this, arguments);

    this.setContentSize(Card.WIDTH * resolutionScale, Card.HEIGHT * resolutionScale);
    this.addBg();

    this.hand.on("showHint", this.showHint.bind(this), this);
    this.hand.on("onHide", this.playHide.bind(this));
});

HandView.prototype.addBg = function () {
    var handBg = this.bg = new cleverapps.Spine(bundles.game.jsons.card_hand_base);
    handBg.setPositionRound(this.width / 2, this.height / 2);
    handBg.setAnimation(0, "idle", true);
    this.addChild(handBg, -1);

    var handCol = this.hand.game.columnsByType(CardColumnTypes.HAND)[0];
    cleverapps.UI.applyHover(handBg, {
        onMouseOver: function () {
            if (!handCol.isEmpty()) {
                handBg.setAnimation(0, "animation", true);
            }
        },
        onMouseOut: function () {
            handBg.setAnimation(0, "idle", true);
        }
    });
    cleverapps.UI.onClick(handBg, function () {
        Game.currentGame.reloadHand();
    });
};

HandView.prototype.adjustPositions = function () {
    this.hand.stack.forEach(function (card, index) {
        var view = card.onGetView();
        if (view.parent === this) {
            var isVisible = index === this.hand.stack.length - 1 || index < 2;

            var pos = cc.p(Card.WIDTH / 2 - Card.GRID_XY * 4, Card.HEIGHT / 2);
            if (index < 2) {
                pos.x += Card.GRID_XY * 2 * (2 - index);
            }

            view.setVisible(isVisible);
            view.setPositionRound(CardView.convertToViewPosition(pos));
        }
    }, this);
};

HandView.prototype.showHint = function () {
    if (!this.hand.isEmpty()) {
        this.hand.stack[this.hand.stack.length - 1].trigger("showHint");
        return;
    }

    var halo = new CardsHintHalo();
    this.bg.addChild(halo, -1);
    halo.setPositionRound(this.bg.width / 2, this.bg.height / 2);
    halo.play();
};

HandView.prototype.calcPosition = function () {
    return {
        x: this.width / 2,
        y: this.height / 2
    };
};

HandView.prototype.playHide = function () {
    this.bg.setAnimation(0, "close", false);
};
