/**
 * Created by spepa on 17.02.2023
 */

var CardColumn = function (type) {
    this.type = type;
    this.cards = [];

    cleverapps.EventEmitter.call(this);

    this.getView = function () {};
    this.isHovered = function () {};
};

CardColumn.prototype = Object.create(cleverapps.EventEmitter.prototype);
CardColumn.prototype.constructor = CardColumn;

CardColumn.prototype.addCards = function (cards, silent) {
    cleverapps.toArray(cards).forEach(function (card) {
        this.cards.push(card);
        card.setOwner(this);
    }.bind(this));

    this.repositionCards();
    if (!silent) {
        this.trigger("updateCards");
    }
};

CardColumn.prototype.repositionCards = function () {
    var offset = cc.p(0, 0);
    this.cards.forEach(function (card, index) {
        card.setPosition(cc.p(Card.WIDTH / 2 - offset.x, Card.HEIGHT / 2 - offset.y));

        if (this.isCardVisible(index)) {
            offset = cc.pAdd(offset, this.getCardOffset(card));
        }
    }, this);
};

CardColumn.prototype.getCardOffset = function (card) {
    if (this.type.getOffset) {
        return this.type.getOffset.call(this, card);
    }
    return cc.p(0, 0);
};

CardColumn.prototype.removeCards = function (cards, silent) {
    cleverapps.toArray(cards).forEach(function (card) {
        this.cards.splice(this.cards.indexOf(card), 1);
    }.bind(this));

    this.repositionCards();
    if (!silent) {
        this.trigger("updateCards");
    }
};

CardColumn.prototype.isEmpty = function () {
    return this.cards.length === 0;
};

CardColumn.prototype.moveCost = function (newOwner, cards) {
    return this.type.moveCost.call(this, newOwner, cards);
};

CardColumn.prototype.canAccept = function (cards, fromDrag) {
    return this.type.canAccept && this.type.canAccept.call(this, cards, fromDrag);
};

CardColumn.prototype.isMovable = function (card, forAuto) {
    return (forAuto || !this.inAnimation()) && this.type.canMove && this.type.canMove.call(this, card);
};

CardColumn.prototype.getTail = function (card) {
    return this.cards.slice(this.cards.indexOf(card));
};

CardColumn.prototype.getHead = function () {
    return this.cards[this.cards.length - 1];
};

CardColumn.prototype.cardClicked = function (card) {
    if (!this.isMovable(card)) {
        return false;
    }

    if (Game.currentGame.tryMove(card.getTail())) {
        return true;
    }

    card.getTail().forEach(function (card) {
        card.trigger("wrongMove");
    });
    return false;
};

CardColumn.prototype.clear = function () {
    this.cards.forEach(function (cards) {
        cards.clear();
    });
};

CardColumn.prototype.inAnimation = function () {
    return this.cards.some(function (card) {
        return card.inAnimation();
    });
};

CardColumn.prototype.flipNormalize = function () {
    if (this.getHead() && !this.getHead().isOpen()) {
        this.getHead().flip();
    }
};

CardColumn.prototype.isCardVisible = function (index) {
    return this.type.cardsVisible === undefined || this.cards.length - index <= this.type.cardsVisible;
};

CardColumn.prototype.calcRect = function () {
    return cc.rect(0, 0, Card.WIDTH, this.getHead() ? 3 * Card.HEIGHT / 2 - this.getHead().y : Card.HEIGHT);
};

CardColumn.prototype.findFirstOpen = function () {
    return this.cards.find(function (card) {
        return card.isOpen();
    });
};