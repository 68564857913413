/**
 * Created by spepa on 28.02.2023
 */

var KlondikeStatusView = cleverapps.Layout.extend({
    ctor: function (game) {
        this.game = game;

        this.createTimer();
        this.createScore();
        this.createMoves();

        var styles = cleverapps.styles.KlondikeStatusView;
        this._super([this.score, this.timer, this.moves], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        this.updateSize();
        this.setupChildren();

        game.timer.on("onSecond", this.updateTime.bind(this));

        game.score.on("onChange", this.updateScore.bind(this), this);
        game.score.on("onHide", this.hide.bind(this), this);
        game.score.on("onShow", this.show.bind(this), this);

        game.onChangeMovesListener = this.createListener(this.updateMoves.bind(this));

        this.hide(true);
    },

    updateScore: function () {
        this.score.txt.setString(Messages.get("Score") + ": " + this.game.score.getPoints());
        this.updateTxtBg(this.score);
    },

    updateMoves: function () {
        this.moves.txt.setString(Messages.get("Moves") + ": " + this.game.moves);
        this.updateTxtBg(this.moves);
    },

    createScore: function () {
        this.score = this.createTxtBg(cleverapps.UI.generateOnlyText(Messages.get("Score") + ": " + this.game.score.getPoints(), cleverapps.styles.FONTS.WHITE_TEXT));
    },

    createMoves: function () {
        this.moves = this.createTxtBg(cleverapps.UI.generateOnlyText(Messages.get("Moves") + ": " + this.game.moves, cleverapps.styles.FONTS.WHITE_TEXT));
    },

    updateTxtBg: function (bg) {
        var styles = cleverapps.styles.KlondikeStatusView.bgPadding;
        if (bg.width <= bg.txt.width + styles.x) {
            bg.width = bg.txt.width + styles.x;
            bg.txt.x = bg.width / 2;
        }
    },

    createTxtBg: function (txt) {
        var styles = cleverapps.styles.KlondikeStatusView.bgPadding;
        var bg = cleverapps.UI.createScale9Sprite(bundles.timer.frames.timer_bg_png);
        bg.setContentSize2(txt.width + styles.x, txt.height + styles.y);
        txt.setPositionRound(bg.width / 2, bg.height / 2);
        bg.addChild(txt);
        bg.txt = txt;
        return bg;
    },

    createTimer: function () {
        var styles = cleverapps.styles.KlondikeStatusView.timer;
        var label = cleverapps.UI.generateOnlyText(Messages.get("Time") + ":", cleverapps.styles.FONTS.WHITE_TEXT);
        
        var bg = cleverapps.UI.createScale9Sprite(bundles.big_timer.frames.big_timer_bg_png);
        bg.setContentSize2(styles);

        this.timer = this.createTxtBg(new cleverapps.Layout([label, bg], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        }));

        var icon = new cc.Sprite(bundles.big_timer.frames.big_timer_png);
        bg.addChild(icon);
        icon.setPositionRound(styles.icon);

        var txt = this.timer.txt = cleverapps.UI.generateImageText("00:00", cleverapps.styles.FONTS.MENUBAR_TIMER_TEXT);
        bg.addChild(txt);
        txt.setPositionRound(styles.txt);
    },

    updateTime: function () {
        var seconds = this.game.timer.getInGameTime();
        this.timer.txt.setString(cleverapps.intervalToString(seconds * 1000, seconds >= 3600));
        this.timer.txt.fitTo(cleverapps.styles.KlondikeStatusView.timer.txt.width);
    },

    updateSize: function () {
        var size = cleverapps.resolution.getSceneSize();
        size.width *= 0.75;
        cleverapps.UI.fitToBox(this, size);
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.KlondikeStatusView.position);
    }
});

cleverapps.styles.KlondikeStatusView = {
    margin: 50,

    bgPadding: {
        x: 20,
        y: 8
    },
    
    timer: {
        width: 240,
        height: 45,
        margin: 10,

        label: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },

        txt: {
            width: 220,
            x: { align: "center", dx: 16 },
            y: { align: "center", dy: 0 }
        },

        icon: {
            x: { align: "left", dx: -10 },
            y: { align: "center", dy: -4 }
        }
    },

    position: [{
        x: { align: "center", dx: -40 },
        y: { align: "top", dy: -25 }
    },
    {
        x: { align: "center", dx: -40 },
        y: { align: "top", dy: -25 }
    },
    {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -25 }
    }]
};